.quickstart {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 350px;
	background-color: #efefef;
	max-height: calc(100vh - 55px);
}

.quickstart .app-sidebar-bg {
	z-index: -1;
}

.quickstart .header {
    width: 100%;
    height: 100px;
    background: linear-gradient(324.71deg, #0B2C50 39.03%, #000000 100%);
    text-align: center;
    position: relative; /* Keeps the relative positioning for the ::before pseudo-element */
    overflow: hidden; /* Keeps overflow hidden for aesthetic purposes */
}

.quickstart .header::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/blue\ o\ only.png'); /* Ensure the path is correct */
    background-repeat: no-repeat;
    background-position: 50% 5%;
    background-size: 100%;
    z-index: 1; /* Lower z-index than the title text */
    opacity: 0.15;
}

.quickstart .header .title {
	position: relative;
    font-size: 2.5rem;
    color: #fff;
    font-weight: bold;
    z-index: 12; /* Ensures the title is above the ::before element */
    margin-top: .7rem; /* Adjust margin as needed */
}

.quickstart .completion-bar, .quickstart .completion-bar-success-message {
    height: 40px;
    width: 90%;
    background: #fff;
	border-top-left-radius: .5rem;
	border-top-right-radius: .5rem;
    position: relative; /* Changed to relative */
    align-self: center; /* Center it within the flex container */
    margin-top: -20px; /* Adjust margin to overlap with the header if needed */
	z-index: 2;
}

.quickstart .completion-bar-success-message {
    padding: 10px;
	font-size: 1.2rem;
	font-weight: bold;
	text-align: center;
	color: #0B2C50;
}

.quickstart .next-steps-message {
	left: 5%;
    width: 90%; /* Full width */
    padding: 50px 15px 0;
	margin-bottom: -15px;
	background-color: #fff;
}

.quickstart .steps {
	left: 5%;
    width: 90%; /* Full width */
    padding: 0 10px; /* Padding for visual spacing */
	background-color: #fff;
	padding-top: 30px;
	border-bottom-left-radius: .5rem;
	border-bottom-right-radius: .5rem;
}

.quickstart .steps .step-header {
	border: none;
	border-top: 1px solid #cdcdcd;
	border-radius: 0;
    width: 100%;
    background-color: transparent;
    color: inherit;
	font-size: 1rem;
	padding: 15px 5px;
}

.quickstart .steps .step-header.disabled {
    background-color: #efefef;
    color: #000;
}

.quickstart .steps .step-content {
	border-radius: 5px;
	padding: 0 5px;
}

.quickstart .close-buttons {
	width: 350px;
    margin-top: auto; /* Pushes .close-buttons to the bottom of the container */
    display: flex;
    justify-content: space-between; /* Evenly spaces the buttons inside */
	bottom: 0;
}

.quickstart .close-buttons div {
	width: 50%;
	justify-content: center;
	text-align: center;
}

.quickstart .close-buttons div button {
	width: 90%;
	margin: 10px 0 10px 5px;
	text-align: center;
    display: inline-block;
    padding: 15px 5px;
	border: 1px solid #000;
	background-color: #0B2C50;
	border-radius: 2rem;
}

.quickstart .close-buttons div:last-child button {
	margin: 10px 5px 10px 0;
}

.quickstartButton {
	width: 180px;
	padding: 15px 5px;
	text-align: center;
	background: #0B2C50;
	border-radius: 2rem;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.24);
	color: #fff;
	position: fixed;
	bottom: 70px;
	right: 10px;
	z-index: 1000;
}

/*  
Quickstart Modal
*/
.custom-modal.modal-dialog {
	max-width: 60vw;
	max-height: 85vh;
	box-shadow: none;
}

.custom-modal .modal-content {
	max-height: 85vh;
	width: 60vw;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06);
}

.scrollable-modal-body {
	overflow-y: auto;
	max-height: calc(85vh - 56px - 56px); /* Adjusts for modal header and footer heights */
	padding: 10px;
}

.custom-modal .modal-content .video-list-container {
	height: 100%;
	overflow-y: auto;
	padding: 10px;
}

.video-row {
	margin-bottom: 20px;
}

.modal-footer-button {
	margin: 15px;
}