.switch-wrapper {
	position: relative;
	display: inline-flex;
	padding: 4px;
	border: 1px solid $light;
	margin-bottom: 40px;
	border-radius: 30px;
	background: #ffffff;
  
	label {
	  font-size: 16px;
	  z-index: 1;
	  min-width: 100px;
	  line-height: 32px;
	  cursor: pointer;
	  border-radius: 30px;
	  transition: color 0.25s ease-in-out;
	  padding: 0 20px;
	  text-align: center;
	  color: #000;
  
	  &:hover {
		background: $light;
	  }
  
	  &.selected {
		color: #ffffff;

		&:hover {
		  background: transparent;
		}
	  }
	}
  
	.highlighter {
	  position: absolute;
	  top: 4px;
	  left: 4px;
	  width: calc(50% - 4px);
	  height: calc(100% - 8px);
	  border-radius: 30px;
	  background: $primary;
	  transition: transform 0.25s ease-in-out;
	  color: #ffffff;
	}
  }

  .switch-wrapper.small {
	position: relative;
	display: inline-flex;
	padding: 5px;
	border: 1px solid $light;
	margin-bottom: 20px;
	border-radius: 15px;
	background: #ffffff;
  
	label {
	  font-size: 16px;
	  z-index: 1;
	  min-width: 50px;
	  line-height: 16px;
	  cursor: pointer;
	  border-radius: 30px;
	  transition: color 0.25s ease-in-out;
	  padding: 2px 10px;
	  text-align: center;
	  color: #000;
  
	  &:hover {
		background: $light;
	  }
  
	  &.selected {
		color: #ffffff;

		&:hover {
		  background: transparent;
		}
	  }
	}
  
	.highlighter {
	  position: absolute;
	  top: 4px;
	  left: 4px;
	  width: calc(50% - 4px);
	  height: calc(100% - 8px);
	  border-radius: 30px;
	  background: $primary;
	  transition: transform 0.25s ease-in-out;
	  color: #ffffff;
	}
  }